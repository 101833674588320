
import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator"
import {TrainDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect
  }
})
export default class NotifyList extends Vue {
  tableItems: TrainDto[] = [];
  typeList: any = [];

  queryForm = {
    name: "",
    phone: null
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType"
      })
      .then(res => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    params.trainId=Number(this.$route.params.id)
    return api.trainRecord.getAll(params);
  }
  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  // 新建
  handleCreate() {
    this.$router.push({name: "trainCreate"});
  }

  // 查看详情
  jumpRecord(index: number, row: TrainDto) {
    this.$router.push({
      name: "trainRecord",
      params: {id: row.id!.toString()}
    });
  }

  // 编辑
  handleEdit(index: number, row: TrainDto) {
    this.$router.push({
      name: "trainEdit",
      params: {id: row.id!.toString()}
    });
  }
  jumpDetail(index: number, row: TrainDto) {
    this.$router.push({
      name: "trainDetail",
      params: {id: row.id!.toString()}
    });
  }

  // 删除
  async handleDelete(index: number, row: TrainDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.trains.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }
}
