
import { Component, Vue } from "vue-property-decorator";
import {NotifyTemplateCreateOrUpdateDto, TrainDto} from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "trainsDetail",
})
export default class NotifyDetail extends Vue {
  detail: TrainDto = {};
  typeList: any = [];

  created() {
    if (this.$route.params.id) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    api.trains.get({ id: Number(this.$route.params.id) }).then(res => {
      this.detail = { ...res };
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
