
import Ueditor from "@/components/Ueditor/index.vue";
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  AttachmentHostType,
  NotifyTemplateCreateOrUpdateDto,
  TrainChapterDto,
  TrainCreateOrUpdateDto
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import SimpleUploadFile from "@/components/SimpleUploadFile/index.vue";
import SingleFileUploadOSS from "@/components/SingleFileUploadOSS/index.vue";

@Component({
  components: {
    SimpleUploadFile,
    SimpleUploadImage,
    Tinymce,
    AbSelect,
    "single-file-upload-oss": SingleFileUploadOSS,
    Ueditor
  }
})
export default class CreateNotify extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: TrainCreateOrUpdateDto = {
    title: undefined,
    content: undefined,
    titleImagePath: undefined,
    trainTypeId: undefined,
    videoPath: undefined,
    isPubilsh: undefined,
    trainChapterList: [],
    id: 0
  };
  typeList: any = [];
  trainTypeList = [];
  get hostType(){
    return AttachmentHostType.Temp;
  }
  created() {
    if (this.$route.params.id) {
      api.trains
        .get({id: Number(this.$route.params.id)})
        .then(res => {
          debugger
          this.form = {...res};
          this.form.trainChapterList=res.trainChapters
        });
    }
    this.fetchEnumType();
  }

  async fetchEnumType() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "TrainType",
        maxResultCount: 1000,
      })
      .then((res: any) => {
        this.typeList = res.items;
      });
  }

  async save(isPublish:boolean) {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {

      if (valid) {
        this.form.isPubilsh=isPublish;
        if (this.form!.id) {
          await api.trains.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.trains.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }

      }
    });
  }
  handleCreateItem() {
    this.createItem({});
  }

  HandleDeleteItem(index: any) {
    this.form.trainChapterList!.splice(index, 1)
  }

  createItem(item: TrainChapterDto) {
    this.form.trainChapterList!.push(item);
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "培训名称必填",
        trigger: "blur"
      }
    ],
    trainTypeId: [
      {
        required: true,
        message: "培训类型必填",
        trigger: "blur"
      }
    ],
    content: [
      {
        required: true,
        message: "培训简介必填",
        trigger: "blur"
      }
    ],

  };
}
