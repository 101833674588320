
import api from "@/api/index"; //ABP API接口
import {Component, Model, Prop, Vue} from "vue-property-decorator";
import {AttachmentCreateOrUpdateDto, AttachmentHostType} from "@/api/appService";
import OSS from "ali-oss"
import Guid from 'guid'
import {UserModule} from "@/store/modules/user";
import {AppModule} from "@/store/modules/app";

@Component({name: 'SingleFileUploadOSS'})
export default class SingleFileUploadOSS extends Vue {

  @Model('change')
  file!: {
    url: '',
    name: '',
    id: 0
  };

  @Prop({required: false})
  hostId!: string;

  @Prop({required: false})
  hostType!: AttachmentHostType;


  async handleSuccess(res: any, file: any) {
    if (res.success) {
      this.$emit('change', res.result.url)
    }
  }

  get headers() {
    return {
      'Abp.OrganizationUnitId': UserModule.getOU?.id,
      'Abp.TenantId': AppModule.getTenantId,
      'Authorization': `Bearer ${UserModule.getToken}`,
      '.AspNetCore.Culture': 'c=zh-Hans|uic=zh-Hans'
    }
  }

  get uploadParameter() {
    return {hostType: this.hostType};
  }


  btnText = '点击上传';
  fileUrl = '';
  imageUrl = '';

  // fnUploadRequest(options: any) {
  //   let fileName = Guid.raw() + '.' + options.file.name.substring(options.file.name.lastIndexOf('.') + 1)
  //   let fileDirectory = 'oss/file/' + fileName;
  //   api.attachmentService.getAliyunOssStsToken().then(res => {
  //     this.fileUrl = res.fileUrl ?? '';
  //     this.imageUrl = res.imageUrl ?? '';
  //
  //     let client = new OSS({
  //       region: res.region,
  //       accessKeyId: res.credentials?.accessKeyId ?? '',
  //       accessKeySecret: res.credentials?.accessKeySecret ?? '',
  //       stsToken: res.credentials?.securityToken,
  //       endpoint: res.endpoint,
  //       bucket: res.bucket,
  //       secure: true,
  //       cname: false
  //     });
  //     console.log("client",client)
  //     let _this = this as any;
  //
  //     // 开始分片上传。
  //     async function multipartUpload() {
  //       try {
  //         debugger
  //         const result = await client.multipartUpload(fileDirectory, options.file, {
  //           progress: (p: any) => {
  //             // 上传进度
  //             // console.log(p); // Object的上传进度。
  //             options.onProgress({percent: p * 100, returnValue: true});
  //             _this.btnText = (p * 100).toFixed(0) + '%'
  //             if (p === 1) {
  //               _this.btnText = '点击上传'
  //             }
  //           }
  //         });
  //         _this.handleSuccess(result, options.file);
  //       } catch (e) {
  //         // 捕获超时异常。
  //         if (e.code === 'ConnectionTimeoutError') {
  //           console.log('TimeoutError');
  //           // do ConnectionTimeoutError operation
  //         }
  //         console.log(e);
  //       }
  //     }
  //
  //     multipartUpload();
  //   })
  // }

  private handleRemove() {
    // this.$emit('change', {});
    this.$emit('change', '');
  }
}
